import React, { useState, useEffect } from 'react';

import API from './utils/api';

import Result from './components/Result/Result';
import Invalid from './components/Invalid/Invalid';
import PasswordReset from './components/PasswordReset'
import Splash from './components/Splash/Splash';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    const checkLink = async (link) => {
      try {
        await API.post('/verifyPasswordLink', { link });
        setIsLinkValid(true);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const link = urlArray[urlArray.length - 1];
    checkLink(link);
  }, []);

  const resetPassword = async (newPassword) => {
    setIsLoading(true);
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const link = urlArray[urlArray.length - 1];
    try {
      await API.put('/user/resetPassword', { link, password: newPassword });
      debugger
      setIsReset(true);
      setIsLoading(false);
    } catch (e) {
      debugger
      setIsLoading(false);
      alert("Une erreur s'est produite, merci de réessayer ultérieurement");
    }
  }

  let body = <Invalid />;
  if (isReset) body = <Result />;
  else if (isLinkValid) body = <PasswordReset onSubmit={resetPassword} />;
  
  return isLoading ? <Splash /> : body;
}


export default App;
