import React, { useState } from 'react';

import Button from '../button/button';
import './PasswordReset.scss';

export default (props) => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [isTouched1, setIsTouched1] = useState(false);
  const [isTouched2, setIsTouched2] = useState(false);

  const isValid1 = password1.length >= 8 
  const isValid2 = password1 === password2;
  const error1 = 'Le mot de passe doit comprendre au moins 8 caractères';
  const error2 = 'Les mots de passe doivent etre identiques';
  let error = 'noerror';
  if (isTouched1 && !isValid1) error = error1;
  if (isTouched2 && !isValid2) error = error2;

  return (
    <div className='slideContainer passwordContainer'>
      <div className='content'>
        <h2>Réinitialise ton mot de passe</h2>

        <form className='passwordForm' onSubmit={() => { if (isValid1 && isValid2) props.onSubmit(password1) }}>
          <p>Saisis ton nouveau mot de passe</p>
          <input type='password' value={password1} placeholder='Mot de passe'
            onChange={e => {
              setPassword1(e.target.value);
              setIsTouched1(true)
            }} />

          <p>Confirme ton nouveau mot de passe</p>
          <input type='password' value={password2} placeholder='Mot de passe'
            onChange={e => {
              setPassword2(e.target.value)
              setIsTouched2(true)
            }} />
        </form>

        {error !== 'noerror' && (
          <p className='small passwordError'>{error}</p>
        )}

        <div className='btnContainer'>
          <Button isDisabled={!isValid1 || !isValid2} clicked={() => props.onSubmit(password1)}>
            Confirmer
          </Button>
        </div>
      </div>
    </div>
  );
}
