import React from 'react';
import { useCurrentWidth } from '../../utils/resize';

import './Result.scss';

const SeeYouSoon = () => {
  return (
    <div className='seeYouContainer'>
      <div className='slideBodySeeYou'>
        <div className='title-logo-container'>
          <h2>À bientôt sur</h2>
          <img
            className='humoon_logo'
            alt='Humoon logo'
            src={require('../../assets/images/humoonLogo.png')} />
          <p>Ton mot de passe a bien été modifié.</p>
        </div>

        <div className='motorbikeContainer'>
          <img
            className='motorbike'
            alt='Motorbike'
            src={useCurrentWidth() >= 416 ? require('../../assets/images/motorbike.png') : 
              require('../../assets/images/smallMotorbike.png')} />
        </div>
      </div>
    </div>
  )
}

export default SeeYouSoon;