import axios from "axios";

import keys from "../config/keys";
const API = axios.create({
  baseURL: keys.baseUrl,
  responseType: "json"
});

API.defaults.headers.common["token"] = keys.API_KEY;

export default API;
